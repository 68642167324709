
import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";
import IAgrupadorTipoFechamentoList from "@/lib/interfaces/agrupador-tipo-fechamento-list";
import IAgrupadorTipoFechamento from "@/lib/interfaces/agrupador-tipo-fechamento";

export default Vue.extend({
  name: "AgrupadorTipoFechamentoList",

  components: {
    ABtn,
    ACard,
    ADataTable,
    ATextField,
    vAlertMessage,
    ASelectStatus,
  },

  props: {
    agrupadorTipoFechamento: {
      type: Object as PropType<IAgrupadorTipoFechamentoList>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",
      desativado: "0",

      options: {} as DataOptions,
      agrupadorTipoFechamentos: new Array<IAgrupadorTipoFechamentoList>(),

      headers: [
        { text: "ID", value: "id", sortable: false },
        {
          text: "Região origem",
          value: "cRegiaoOrigem",
          sortable: false,
        },
        {
          text: "Região destino",
          value: "cRegiaoDestino",
          sortable: false,
        },
        {
          text: "Centro de custo",
          value: "centro_custo_nome",
          sortable: false,
        },
        {
          text: "Fechamento",
          value: "descricao",
          sortable: false,
        },
        { text: "Status", value: "cDesativado", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getAgrupadorTipoFechamentos().then();
    },

    options: {
      deep: true,
      handler() {
        this.getAgrupadorTipoFechamentos().then();
      },
    },
  },

  computed: {
    cAgrupadorTipoFechamentos(): Array<Record<string, unknown>> {
      return this.agrupadorTipoFechamentos.map((item) => ({
        ...item,
        cRegiaoOrigem: `${item.id_regiao_suni} - ${item.nome_regiao_suni}`,
        cRegiaoDestino: `${item.id_regiao_atual} - ${item.nome_regiao_atual}`,
        cDesativado: item.desativado === "0" ? "Ativo" : "Inativo",
      }));
    },
  },

  methods: {
    async openAgrupadorTipoFechamento(id: string) {
      const f = this.agrupadorTipoFechamentos.find((item) => item.id === id);
      if (!f) return;

      const item: IAgrupadorTipoFechamento = {
        id: f.id,
        id_aplicacao_importador_origem: f.id_agrupamento,
        id_tipo_fechamento: f.tipo_fechamento_id,
        desativado: f.desativado,
      };

      this.$emit("update:agrupador-tipo-fechamento", { ...item });
    },

    async getAgrupadorTipoFechamentos() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("cadastro_tipo_fechamento_regiao.readSearch", {
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.agrupadorTipoFechamentos =
          rsp.getTable("agrupador_tipo_fechamento")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
