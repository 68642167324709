
import Vue from "vue";
import AgrupadorTipoFechamentoList from "@/components/subviews/agrupador-tipo-fechamento/agrupador-tipo-fechamento-list.vue";
import AgrupadorTipoFechamentoMaintenance from "@/components/subviews/agrupador-tipo-fechamento/agrupador-tipo-fechamento-maintenance.vue";
import IAgrupadorTipoFechamentoList from "@/lib/interfaces/agrupador-tipo-fechamento-list";

export default Vue.extend({
  name: "AgrupadorTipoFechamento",

  components: {
    AgrupadorTipoFechamentoList,
    AgrupadorTipoFechamentoMaintenance,
  },

  data() {
    return {
      refresh: 0,

      currentTab: 0,

      agrupadorTipoFechamento: {} as IAgrupadorTipoFechamentoList,
    };
  },
});
