
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq, Table } from "@/lib/amdt";
import { required, maxLength } from "@/lib/validations";
import WorgError from "@/lib/worg-error";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ACard from "@/components/elements/a-card.vue";
import ABtn from "@/components/elements/a-btn.vue";
import VForm from "@/lib/types/v-form";
import IAgrupadorTipoFechamento from "@/lib/interfaces/agrupador-tipo-fechamento";
import ITipoFechamento from "@/lib/interfaces/tipo-fechamento";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import IAplicaoOrigem from "@/lib/interfaces/aplicacao-origem";

export default Vue.extend({
  name: "AgrupadorTipoFechamentoMaintenance",

  components: {
    ABtn,
    ACard,
    vAlertMessage,
    ABtnDialogConfirm,
    AAutocomplete,
  },

  props: {
    agrupadorTipoFechamento: {
      type: Object as PropType<IAgrupadorTipoFechamento>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      id_tipo_fechamento: "",
      ids_aplicacao_importador_origem: [] as string[],

      agrupados: [] as IAgrupadorTipoFechamento[],

      tiposFechamento: [] as ITipoFechamento[],
      aplicacaoImportadorOrigens: [] as IAplicaoOrigem[],
    };
  },

  watch: {
    agrupadorTipoFechamento: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";
        this.id_tipo_fechamento =
          this.agrupadorTipoFechamento.id_tipo_fechamento;
      },
    },

    id_tipo_fechamento() {
      this.getAgrupados().then();
    },

    agrupados() {
      this.ids_aplicacao_importador_origem = this.agrupados
        .filter((item) => item.desativado == "0")
        .map((item) => item.id_aplicacao_importador_origem);
    },
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    cAplicacaoImportadorOrigens(): Record<string, string>[] {
      return this.aplicacaoImportadorOrigens.map((item) => ({
        value: item.id,
        text: `${item.id_aplicacao} - ${item.aplicacao_nome} - ${item.id_pessoa} - ${item.nome_regiao} - ${item.id_centro_custo} - ${item.centro_custo_nome} - ${item.regiao_origem} - ${item.nregiao}`,
      }));
    },

    cAgrupados(): IAgrupadorTipoFechamento[] {
      // filtramos os registros que estavam ativos e foram desativados na sessão.
      const desativados = this.agrupados.filter(
        (item) =>
          !this.ids_aplicacao_importador_origem.includes(
            item.id_aplicacao_importador_origem
          )
      );

      // popula array com itens que serão enviados para o servidor para update ou insert
      // começamos incluindo os registros que foram desativados
      const items = desativados.map((item) => ({
        ...item,
        desativado: "1",
      })) as IAgrupadorTipoFechamento[];

      // filtramos os registros que estavam desativados e foram ativados na sessão
      const ativos = this.agrupados
        .filter((item) => item.desativado == "1")
        .filter((item) =>
          this.ids_aplicacao_importador_origem.includes(
            item.id_aplicacao_importador_origem
          )
        );

      for (const item of ativos) items.push({ ...item, desativado: "0" });

      // filtramos os registros que não existem no banco de dados
      for (const id of this.ids_aplicacao_importador_origem) {
        const f = this.agrupados.find(
          (agp) => agp.id_aplicacao_importador_origem == id
        );

        // se o registro já existe no banco de dados, não incluímos no array
        if (f) continue;

        items.push({
          id: "default",
          id_aplicacao_importador_origem: id,
          id_tipo_fechamento: this.id_tipo_fechamento,
          desativado: "0",
        });
      }

      return items;
    },
  },

  created() {
    this.getData().then();
  },

  methods: {
    maxLength,
    required,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("cadastro_tipo_fechamento_regiao.save");

        const tbl = new Table("agrupador_tipo_fechamento");
        const obj = { ...this.cAgrupados[0] };

        tbl.setColsFromObject(obj);
        tbl.addRowsFromObject(...this.cAgrupados.map((item) => ({ ...item })));

        rq.addTable(tbl);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          return;
        }

        this.ret_id = 1;
        this.ret_msg = "Vínculos atualizados com sucesso";

        this.$emit("updated");
        this.getAgrupados().then();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.id_tipo_fechamento = "";
      this.ids_aplicacao_importador_origem = [];
      this.agrupados = [];
      this.form.resetValidation();
    },

    async getData() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("cadastro_tipo_fechamento_regiao.readDropDrow", {
          itemsPerPage: "-1",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.aplicacaoImportadorOrigens =
          rsp.getTable("aplicacao_origem")?.getRowsObject() ?? [];
        this.tiposFechamento =
          rsp.getTable("tipo_fechamento")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getAgrupados() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("cadastro_tipo_fechamento_regiao.agrupados", {
          id_tipo_fechamento: this.id_tipo_fechamento,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.agrupados =
          rsp.getTable("agrupador_tipo_fechamento")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
